<template>
  <div class="section-care-child">
    <BaseH2 v-html="'Opieka nad dzieckiem'" />
    <BaseGroupChip :items="childCare" :items-crossed="crossedChildCare" />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

import useApplicationNannyDictionary from "@/composables/useApplicationNannyDictionary";

import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseGroupChip from "@/components/UI/Base/BaseGroupChip.vue";

export default {
  components: { BaseH2, BaseGroupChip },

  props: {
    childCare: Array,
  },

  setup(props) {
    const { DICTIONARY } = useApplicationNannyDictionary();

    const crossedChildCare = computed(() => {
      const childCareItems = props.childCare;
      const notIncludedEntries = [];

      if (childCareItems) {
        let allChildCareSlugs = Object.keys(
          DICTIONARY.responsibilities.childCare
        );
        for (let slug of allChildCareSlugs) {
          if (!childCareItems.some((item) => item.slug === slug)) {
            notIncludedEntries.push(
              DICTIONARY.responsibilities.childCare[slug]
            );
          }
        }
      }

      return notIncludedEntries;
    });

    return { crossedChildCare };
  },
};
</script>

<style scoped>
.section-care-child {
  width: 100%;

  display: flex;
  flex-flow: column;
  row-gap: 20px;
}
</style>
