<template>
  <BaseButton class="button" :label="''">
    <SvgIconsArrowWest24 v-if="size === 'small'" />
    <SvgIconsArrowWest36 v-else />
  </BaseButton>
</template>

<script>
import BaseButton from "@/components/UI/Base/BaseButton.vue";

import SvgIconsArrowWest24 from "@/components/Svg/Icons/SvgIconsArrowWest24.vue";
import SvgIconsArrowWest36 from "@/components/Svg/Icons/SvgIconsArrowWest36.vue";

export default {
  components: { BaseButton, SvgIconsArrowWest24, SvgIconsArrowWest36 },

  props: {
    size: {
      type: String,
      default: "medium",
    },
  },
};
</script>

<style scoped>
.button {
  opacity: 0.4;
}
.button:hover {
  opacity: 1;
}
@media (max-width: 1200px) {
  .button {
    opacity: 1;
  }
}
</style>
