<template>
  <div class="section-advantages">
    <BaseGroupIconLabel
      :items="readyItems"
      class="advantages section-advantages__advantages"
    />
  </div>
</template>

<script>
import BaseGroupIconLabel from "@/components/UI/Base/BaseGroupIconLabel.vue";
import SvgIconsLanguage24 from "@/components/Svg/Icons/SvgIconsLanguage24.vue";

import { computed } from "@vue/reactivity";

export default {
  components: { BaseGroupIconLabel },

  props: {
    advantages: Array,
    languages: Array,
  },

  setup(props) {
    const readyItems = computed(() => {
      let languagesString = props.languages.reduce(
        (result, language, index) => {
          if (index === 1) {
            result = result.label;
          }
          return result + ", " + language.label;
        }
      );

      return [
        {
          slug: "languages",
          label: "Znajomość języków: " + languagesString,
          iconComponent: SvgIconsLanguage24,
        },
        ...props.advantages,
      ];
    });
    return { readyItems };
  },
};
</script>

<style scoped>
.section-advantages {
  width: 100%;

  padding: 30px;

  background-color: #e5eaf9;
  border-radius: 10px;
}
.section-advantages .section-advantages__advantages {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}
</style>
