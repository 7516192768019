<template>
  <div class="container-photo-gallery">
    <img
      v-for="(img, n) in galleryImgs"
      :key="img.src"
      :src="img.src"
      @click="$emit('openGallery', { frameNum: n + 1 })"
      class="img container-photo-gallery__img"
    />
  </div>
</template>

<script>
export default {
  props: {
    galleryImgs: Array,
  },

  emits: ["openGallery"],
};
</script>

<style scoped>
.container-photo-gallery {
  display: flex;
  flex-flow: row;
  column-gap: 20px;
}
.container-photo-gallery__img {
  object-fit: cover;
  width: calc((var(--layout-view-block-21--column-width) - 60px) / 4);
  height: calc((var(--layout-view-block-21--column-width) - 60px) / 4);
  border-radius: 10px;
}
.container-photo-gallery__img:hover {
  cursor: pointer;
}
</style>
