<template>
  <div class="section-shedule">
    <div class="wrapper-title section-shedule__wrapper-title">
      <BaseH2 v-html="'Grafik'" />
      <BaseText v-html="startDay" />
    </div>
    <Schedule :schedule-value="scheduleData" />
    <BlockTextExpandableComment :text="scheduleComment" :max-length="200" />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";
import Schedule from "@/components/Schedule.vue";
import BlockTextExpandableComment from "@/components/BlockTextExpandableComment.vue";

export default {
  components: {
    BaseH2,
    BaseText,
    Schedule,
    BlockTextExpandableComment,
  },

  props: {
    scheduleDateStartString: String,
    scheduleDateStartDaysBeforeStart: [Number],
    scheduleData: Array,
    scheduleComment: String,
  },

  setup(props) {
    const startDay = computed(() => {
      if (props.scheduleDateStartDaysBeforeStart > 0) {
        return `Start od ${props.scheduleDateStartString}, przez ${props.scheduleDateStartDaysBeforeStart} dni`;
      } else {
        return `Start ${props.scheduleDateStartString}`;
      }
    });

    return { startDay };
  },
};
</script>

<style scoped>
.section-shedule {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 20px;
}

.section-shedule__wrapper-title {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  /* row-gap: 10px; */
}
</style>
