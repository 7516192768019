<template>
  <div class="review list-reviews__review">
    <div class="header review__header">
      <span class="proof-name header__proof-name">{{ proofName }}</span>
      <div class="rating header__rating" :class="`header__rating--${rating}`">
        <span class="icon rating__icon" />
        <label class="label rating__label">{{ ratingLabel }}</label>
      </div>
      <span class="verification header__verification" :verified="isVerified">{{
        isVerified ? "Zweryfikowana" : "Nie zweryfikowana"
      }}</span>
    </div>
    <div class="review-body review__review-body">
      <BaseText v-html="readyText" class="text review-body__text" />
      <ButtonLinkMore
        v-if="isTextOverflow"
        :isLess="isBlockExpanded"
        :size="'small'"
        class="button-more review-body__button-more"
        @click="isBlockExpanded = !isBlockExpanded"
      />
    </div>

    <div class="source review__source">Zrodło: {{ source }}</div>
  </div>
</template>

<script>
import { computed, toRef } from "@vue/reactivity";

import useBlockTextExpandable from "@/composables/useBlockTextExpandable";

import ButtonLinkMore from "@/components/UI/ButtonLinkMore.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";

export default {
  components: { ButtonLinkMore, BaseText },

  props: {
    id: String,
    proofName: {
      type: String,
      default: "",
    },
    reviewText: String,
    source: {
      type: String,
      default: "",
    },
    isVerified: {
      type: Boolean,
      default: false,
    },
    rating: Number,
    maxReviewLength: {
      type: Number,
      default: 300,
    },
  },

  setup(props) {
    const { isBlockExpanded, isTextOverflow, readyText, blockBorderRadius } =
      useBlockTextExpandable({
        textRef: toRef(props, "reviewText"),
        maxLengthRef: toRef(props, "maxReviewLength"),
      });

    const ratingLabel = computed(() => {
      switch (props.rating) {
        case 1:
          return "Nie polecam";
        case 2:
          return "Polecam";
      }
      return "Bez oceny";
    });

    return {
      isBlockExpanded,
      isTextOverflow,
      readyText,
      ratingLabel,
      blockBorderRadius,
    };
  },
};
</script>

<style scoped>
.list-reviews__review {
  padding: 30px 40px 40px 40px;
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 25px;

  background-color: #f5faff;
  border-radius: v-bind(blockBorderRadius);
}
.review__header {
  width: 100%;
  display: flex;
  flex-flow: row;
  column-gap: 10px;
}
.header__proof-name {
  width: calc(100% / 3);
  font: var(--h3--font);
}
.header__rating {
  width: calc(100% / 3);
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  column-gap: 7px;
}
.rating__icon {
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
}
.header__rating--1 .rating__icon {
  background: center / contain no-repeat
    url("~@/assets/images/icons/rating/rating_1.png");
}
.header__rating--2 .rating__icon {
  background: center / contain no-repeat
    url("~@/assets/images/icons/rating/rating_2.png");
}
.rating__label {
  font: var(--system-font);
  font-size: var(--default-font-size);
}
.header__verification {
  width: calc(100% / 3);
  display: flex;
  justify-content: flex-end;
  align-items: center;

  font: var(--system-font);
  font-size: var(--default-font-size);
}
.header__verification[verified="true"] {
  color: #70a46f;
}
.header__verification[verified="false"] {
  color: #fe881c;
}
.review__source {
  font: var(--system-font);
  opacity: 0.5;
}
.review__review-body {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 10px;
}

@media (max-width: 1200px) {
  .list-reviews__review {
    padding: 20px 30px 20px 30px;
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: flex-start;
    row-gap: 20px;
  }
  .review__header {
    width: 100%;

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: baseline;
    column-gap: 10px;
    row-gap: 15px;
  }
  .header__proof-name {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
  }
  .header__rating {
    order: 2;

    width: auto;

    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    column-gap: 7px;
  }
  .rating__label {
    font: var(--system-font);
  }
  .header__verification {
    width: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    font: var(--system-font);
  }
  .review__review-body {
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 0;
  }
}
</style>
