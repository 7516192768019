<template>
  <div class="section-title">
    <BaseH1 :text="title" class="title" />
    <div class="tags section-title__tags">
      <div class="geo tags__geo">
        <SvgIconsLocation24 class="icon geo__icon" />
        <label class="label geo__label">{{ geoLabel }} od Ciebie</label>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIconsLocation24 from "@/components/Svg/Icons/SvgIconsLocation24.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";

export default {
  components: { SvgIconsLocation24, BaseH1 },

  props: {
    title: {
      type: String,
      required: true,
    },
    geoLabel: String,
  },
};
</script>

<style scoped>
.section-title {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 5px;
}

.tags__geo {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  column-gap: 5px;
}
.geo__label {
  font: var(--system-font);
}
</style>
