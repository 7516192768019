<template>
  <div class="section-edu" :class="isAccent ? 'section-edu--accent' : ''">
    <BaseH2 v-html="'Wykształcenie'" />
    <div class="container section-edu__container">
      <div class="edu-type container__edu-type">
        <BaseH3 v-html="educationDegree" />
        <div class="wrapper edu-type__wrapper">
          <BaseGroupIconLabelItem
            v-for="eduType in educationTypes"
            :key="eduType.slug"
            :label="eduType.label"
            :icon-component="eduType.iconComponent"
          />
        </div>
      </div>
      <div v-if="educationCourses.length" class="courses container__courses">
        <BaseH3 v-html="'Kursy'" />
        <div class="wrapper courses__wrapper">
          {{ readyCourses.join(", ") }}
        </div>
      </div>
    </div>

    <BlockTextExpandableComment
      v-if="educationComment"
      :text="educationComment"
      :max-length="100"
      :style="!isAccent ? 'background-color: #D8D7F9;' : ''"
    />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";
import { toFirstCharUpper } from "@/helpers";

import BlockTextExpandableComment from "@/components/BlockTextExpandableComment.vue";
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseH3 from "@/components/UI/Base/BaseH3.vue";
import BaseGroupIconLabelItem from "@/components/UI/Base/BaseGroupIconLabelItem.vue";

export default {
  components: {
    BlockTextExpandableComment,
    BaseH2,
    BaseH3,
    BaseGroupIconLabelItem,
  },

  props: {
    isAccent: {
      type: Boolean,
      default: false,
    },
    educationDegree: {
      type: String,
      required: true,
    },
    educationTypes: Array,
    educationCourses: Array,
    educationComment: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    const readyCourses = computed(() => {
      const courses = [];
      const eduCoursesObjs = props.educationCourses;
      if (eduCoursesObjs.length) {
        eduCoursesObjs.forEach((course, index) => {
          if (index === 0) {
            courses.push(toFirstCharUpper(course.label));
          } else {
            courses.push(course.label);
          }
        });
      }

      return courses;
    });

    return { readyCourses };
  },
};
</script>

<style scoped>
.section-edu {
  padding: 50px 60px 60px 60px;
  width: var(--layout-view-block-11--column-width);

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 40px;
}
.section-edu--accent {
  background: #fff;
  border-radius: 10px;
}
.section-edu__container {
  width: 100%;
  display: flex;
  flex-flow: row;
  column-gap: 40px;
}
.container__edu-type {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 15px;
}
.container__courses {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 15px;
}
.edu-type__wrapper {
  display: flex;
  flex-flow: column;
  row-gap: 10px;
}
.courses .courses__wrapper {
  font: var(--font-small);
}

@media (max-width: 1200px) {
  .section-edu {
    padding: 0;
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: flex-start;
    row-gap: 20px;
  }
  .section-edu__container {
    width: 100%;

    display: flex;
    flex-flow: row;
    column-gap: 10px;
  }
  .container__edu-type {
    min-width: calc((100% - 10px) / 2);
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    row-gap: 7px;
  }
  .container__courses {
    row-gap: 5px;
  }
}
</style>
