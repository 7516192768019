<template>
  <div class="section-care-activities">
    <BaseH2 v-html="'Mogę zajmować się...'" />
    <BaseGroupChip :items="readyActivities" />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseGroupChip from "@/components/UI/Base/BaseGroupChip.vue";

export default {
  components: { BaseH2, BaseGroupChip },

  props: {
    activities: Array,
  },

  setup(props) {
    const readyActivities = computed(() => {
      let activities = [];
      if (props.activities.length) {
        props.activities.forEach((activity) => {
          activities.push({
            slug: activity.slug,
            label: activity.labelShort,
          });
        });
      }

      return activities;
    });

    return { readyActivities };
  },
};
</script>

<style scoped>
.section-care-activities {
  width: 100%;

  display: flex;
  flex-flow: column;
  row-gap: 20px;
}
</style>
