<template>
  <div class="section-shedule">
    <div class="wrapper-title section-shedule__wrapper-title">
      <BaseH2 v-html="'Grafik'" />
      <BaseText v-html="startDay" />
    </div>
    <BlockContent11>
      <template #left>
        <Schedule :schedule-value="scheduleData" />
      </template>
      <template #right>
        <div class="wrapper-right section-shedule__wrapper-right">
          <BlockTextQoute :text="textQuote" />
          <BlockFee :fee-hour="feeHour" :fee-month="feeMonth" />
          <BlockTextExpandableComment
            :text="scheduleComment"
            :max-length="200"
          />
        </div>
      </template>
    </BlockContent11>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

import Schedule from "@/components/Schedule";
import BlockTextQoute from "@/components/BlockTextQoute";
import BlockFee from "@/components/BlockFee.vue";
import BlockTextExpandableComment from "@/components/BlockTextExpandableComment.vue";
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";
import BlockContent11 from "@/layouts/BlockContent11.vue";

export default {
  components: {
    Schedule,
    BlockTextQoute,
    BlockFee,
    BlockTextExpandableComment,
    BaseH2,
    BaseText,
    BlockContent11,
  },

  props: {
    scheduleDateStartString: String,
    scheduleDateStartDaysBeforeStart: [Number],
    scheduleData: Array,
    textQuote: String,
    feeMonth: Number,
    feeHour: Number,
    scheduleComment: String,
  },

  setup(props) {
    const startDay = computed(() => {
      if (props.scheduleDateStartDaysBeforeStart > 0) {
        return `Start od ${props.scheduleDateStartString}, przez ${props.scheduleDateStartDaysBeforeStart} dni`;
      } else {
        return `Start ${props.scheduleDateStartString}`;
      }
    });

    return { startDay };
  },
};
</script>

<style scoped>
.section-shedule {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 30px;
}

.section-shedule__wrapper-title {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  /* row-gap: 10px; */
}

.section-shedule__wrapper-right {
  display: flex;
  flex-flow: column;
  align-items: center;

  row-gap: 40px;
}
</style>
