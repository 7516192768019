<template>
  <div class="section-photo">
    <div v-if="isArchived" class="section-photo__archived-tag-wrapper">
      <BaseTag :text="'Ankieta archiwalna'" />
    </div>
    <BlockPhotoMain
      :src="photoMain?.src"
      :defaultColorNum="photoMainColorNum"
      :years-label="yearsLabel"
      @click="openGallery(0)"
      :class="[
        'photo-main section-photo__photo-main',
        { 'is-archived': isArchived },
      ]"
    />
    <transition name="gallery">
      <ApplicationNannyDetailsSectionPhotoGallery
        v-if="photoGallery.length > 0 && !isArchived"
        v-show="!isPhotoGalleryHidden"
        :gallery-imgs="photoGallery"
        @open-gallery="openGallery($event.frameNum)"
        class="photo-gallery section-photo__photo-gallery"
      />
    </transition>
    <PhotoGalleryDesktop
      ref="galleryRef"
      :photoMainImg="photoMain"
      :photoGalleryImgs="photoGallery"
      :defaultColorNum="photoMainColorNum"
    />
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import BlockPhotoMain from "@/components/BlockPhotoMain.vue";
import ApplicationNannyDetailsSectionPhotoGallery from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionPhotoGallery.vue";
import PhotoGalleryDesktop from "@/components/PhotoGalleryDesktop.vue";
import BaseTag from "@/components/UI/Base/BaseTag.vue";

export default {
  components: {
    BlockPhotoMain,
    ApplicationNannyDetailsSectionPhotoGallery,
    PhotoGalleryDesktop,
    BaseTag,
  },

  props: {
    photoMain: Object,
    photoGallery: Array,
    photoMainColorNum: Number,
    isPhotoGalleryHidden: {
      type: Boolean,
      default: false,
    },
    isArchived: {
      type: Boolean,
      default: false,
    },
    contractorAge: Object,
  },

  setup(props) {
    const galleryRef = ref(null);

    const yearsLabel = computed(() => {
      return `${props.contractorAge.years} lat`;
    });

    const openGallery = (frameNum) => {
      if (galleryRef.value === null || props.isArchived) {
        return;
      }
      galleryRef.value.open(frameNum);
    };

    return { galleryRef, openGallery, yearsLabel };
  },
};
</script>

<style scoped>
.section-photo {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  position: relative;
}
.section-photo__archived-tag-wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(var(--tag-height) / -2);
}
.section-photo__photo-main {
  z-index: 10;
}
.section-photo__photo-main.is-archived {
  opacity: 0.5;
  pointer-events: none;
}
.section-photo__photo-gallery {
  margin-top: 20px;
  will-change: transform, opacity, margin, min-height;
}
.gallery-enter-from,
.gallery-leave-to {
  margin: 0;
  opacity: 0;
  transform: translateY(-40px);
  max-height: 0;
}
.gallery-enter-to,
.gallery-leave-from {
  opacity: 1;
  transform: translateY(0);
  max-height: 100px;
}
.gallery-enter-active {
  transition: all 0.4s;
}
.gallery-leave-active {
  transition: all 0.2s;
}
</style>
