<template>
  <BaseButton :to="to" :disabled="isDisabled" :label="''" class="button">
    <SvgIconsWishlist24 v-if="size === 'small' && !isWishlisted" />
    <SvgIconsWishlist24Filled v-if="size === 'small' && isWishlisted" />

    <SvgIconsWishlist36 v-else-if="size === 'medium' && !isWishlisted" />
    <SvgIconsWishlist36Filled v-else-if="size === 'medium' && isWishlisted" />
  </BaseButton>
</template>

<script>
import BaseButton from "@/components/UI/Base/BaseButton.vue";

import SvgIconsWishlist24 from "@/components/Svg/Icons/SvgIconsWishlist24.vue";
import SvgIconsWishlist36 from "@/components/Svg/Icons/SvgIconsWishlist36.vue";
import SvgIconsWishlist36Filled from "@/components/Svg/Icons/SvgIconsWishlist36Filled.vue";
import SvgIconsWishlist24Filled from "@/components/Svg/Icons/SvgIconsWishlist24Filled.vue";

export default {
  components: {
    BaseButton,
    SvgIconsWishlist24,
    SvgIconsWishlist24Filled,
    SvgIconsWishlist36,
    SvgIconsWishlist36Filled,
  },

  props: {
    to: {
      type: [String, Object],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isWishlisted: {
      type: Boolean,
    },
    size: {
      type: String,
      default: "medium",
    },
  },
};
</script>

<style scoped>
.button {
  opacity: 0.4;
}
.button:hover {
  opacity: 1;
}
@media (max-width: 1200px) {
  .button {
    opacity: 1;
  }
}
</style>
