<template>
  <component :is="applicationNannyDetailsComponent"> </component>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { onMounted } from "vue";

import useIsMobile from "@/composables/useIsMobile";
import useAnonymusAdGuard from "@/composables/useAnonymusAdGuard";

import ApplicationNannyDetailsDesktop from "@/views/Application/Nanny/Details/ApplicationNannyDetailsDesktop.vue";
import ApplicationNannyDetailsMobile from "@/views/Application/Nanny/Details/ApplicationNannyDetailsMobile.vue";

export default {
  setup() {
    const { isMobile } = useIsMobile();

    const applicationNannyDetailsComponent = computed(() => {
      if (isMobile.value) return ApplicationNannyDetailsMobile;
      return ApplicationNannyDetailsDesktop;
    });

    const { countAdClick } = useAnonymusAdGuard(
      ref("application"),
      ref("nanny")
    );

    onMounted(() => {
      countAdClick();
    });

    return { applicationNannyDetailsComponent };
  },
};
</script>
