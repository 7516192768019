import useAuth from "@/composables/useAuth";
import { VISIT_APPLICATION_NANNY } from "@/graphql/mutations/applicationNanny";
import { GET_APPLICATION_NANNY } from "@/graphql/queries/applicationNanny";
import { prepareApplicationNannyInstance } from "@/helpers";
import { ROUTE_PUSH_TO_PREMIUM_NAME } from "@/router/constants";
import { useUserStore } from "@/stores/user";
import { useUserWishlistStore } from "@/stores/userWishlist";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { ref, computed } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { useRouter } from "vue-router";

export default function useApplicationNannyDetails(id) {
  const userStore = useUserStore();
  const { isAuth } = useAuth();
  const router = useRouter();
  const userWishlistStore = useUserWishlistStore();

  const application = ref(null);
  const isPhotoGalleryHidden = ref(false);
  const popupCallRef = ref(null);

  const popupPushToRegistrationRef = ref(null);
  const popupPushToRegDescription = ref("");

  const isWishlisted = computed(() => {
    return userWishlistStore.applications.nanny_ids.includes(id);
  });

  const textQuote = computed(() => {
    if (application.value === null) return null;

    let text = "";
    const types = application.value.employment.types;
    const regularities = application.value.employment.regularities;
    const aupairLabel = application.value.details.aupair.label.toLowerCase();

    if (types.length > 0 && regularities.length > 0) {
      let employmentType =
        types.length > 1
          ? `<b>${types[0].labelShort}</b>` +
            " albo " +
            `<b>${types[1].label}</b>`
          : `<b>${types[0].label}</b>`;

      let employmentRegularity = "";
      if (regularities.length > 1) {
        regularities.forEach((regularity, index) => {
          if (index === 0) {
            employmentRegularity = "Może być " + regularity.label.toLowerCase();
          } else if (index === regularities.length - 1) {
            employmentRegularity =
              employmentRegularity + " oraz " + regularity.label.toLowerCase();
          } else {
            employmentRegularity =
              employmentRegularity + ", " + regularity.label.toLowerCase();
          }
        });
      } else if (regularities.length === 1) {
        employmentRegularity = regularities[0].label;
      }

      text = `Szukam pracę ${aupairLabel}.<br />
    Na ${employmentType}.<br />
    ${employmentRegularity}.`;
    }

    return text;
  });

  const isClient = computed(() => {
    return userStore.userType === "client";
  });

  const isArchived = computed(() => {
    if (application.value === null) {
      return false;
    }
    return application.value.meta.status === "archived";
  });

  const canShowPopupCall = computed(() => {
    return isAuth.value && isClient.value && _hasClientPremium();
  });

  onMounted(() => {
    const { mutate: updateVisits } = useMutation(VISIT_APPLICATION_NANNY);

    initApplication().then((application) => {
      const variables = getVisitMutationVariables(application);
      updateVisits(variables);
    });
  });

  const initApplication = async () => {
    return new Promise((resolve, reject) => {
      const { onResult, onError } = useQuery(
        GET_APPLICATION_NANNY,
        () => ({
          id: id,
          populatePath:
            "user_profile_id_populated recommendation_ids_populated",
        }),
        () => ({
          fetchPolicy: "cache-and-network",
        })
      );

      onResult((result) => {
        const applicationNannyRaw = result.data.applicationNanny;

        if (applicationNannyRaw === null) return;

        application.value =
          prepareApplicationNannyInstance(applicationNannyRaw);

        resolve(applicationNannyRaw);
      });

      onError((error) => reject(error.message));
    });
  };

  const getVisitMutationVariables = (application) => {
    const variables = { id: application._id };

    const user = useUserStore();
    const user_id = user._id;
    const isAuth = user.isAuth;
    if (
      isAuth &&
      user_id &&
      application.meta.visitor_user_ids?.includes(user_id) === false
    ) {
      Object.assign(variables, { visitorUserId: user_id });
    }

    return variables;
  };

  const handleTitleIntersection = (event) => {
    isPhotoGalleryHidden.value = !event.detail.isOnScreen;
  };

  const openPopupCall = () => {
    if (isAuth.value === false) {
      // show popup with push to registration
      return _showPopupPushToReg("call");
    }

    if (_hasClientPremium() === false) {
      // redirect to "Push to premium" view
      const ROUTE_PUSH_TO_PREMIUM = { name: ROUTE_PUSH_TO_PREMIUM_NAME };
      return router.push(ROUTE_PUSH_TO_PREMIUM);
    }

    if (canShowPopupCall.value && popupCallRef.value) {
      // show popup with nanny's phone number
      popupCallRef.value.open();
    }
  };

  const handleWishlistToggler = () => {
    if (isAuth.value === false) {
      // show popup with push to registration
      return _showPopupPushToReg("wishlist");
    }

    if (isWishlisted.value === true) {
      userWishlistStore.pullApplicationFromWishlist(id);
    } else if (isWishlisted.value === false) {
      userWishlistStore.pushApplicationToWishlist(id);
    }
  };

  const _showPopupPushToReg = (target) => {
    if (popupPushToRegistrationRef.value === null) {
      return;
    }

    if (target === "call") {
      popupPushToRegDescription.value =
        "Aby mieć możliwość kontaktu z opiekunkami";
      return popupPushToRegistrationRef.value.open();
    }

    if (target === "wishlist") {
      popupPushToRegDescription.value =
        "Aby dodawać profili niań do ulubionych";
      return popupPushToRegistrationRef.value.open();
    }
  };

  const _hasClientPremium = () => {
    // TODO: fix this after Purchases done
    return false;
    // return premiumservice.hasUserPremium(user_id)
  };

  return {
    application,
    isPhotoGalleryHidden,
    handleTitleIntersection,
    textQuote,

    handleWishlistToggler,

    isWishlisted,
    isClient,
    isArchived,
    popupCallRef,
    openPopupCall,
    canShowPopupCall,

    popupPushToRegistrationRef,
    popupPushToRegDescription,
  };
}
