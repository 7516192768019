<template>
  <div class="content" v-if="application">
    <block-content-21>
      <template #left>
        <div class="container-left content__container-left">
          <ApplicationNannyDetailsSectionTitle
            v-intersection
            @intersection="handleTitleIntersection($event)"
            :title="application.user_profile_id_populated.nameFull"
            :languages="application.details.languages"
            :activeness="application.user_profile_id_populated.activeness"
            :geo-label="application.details.location.label"
          />
          <BlockTextExpandableMain :text="application.details.description" />
          <BaseGroupItemIconLabel :items="application.advantages" />
          <ApplicationNannyDetailsSectionCareChild
            :child-care="application.responsibilities.careChild"
            :activities="application.responsibilities.activities"
          />
        </div>
      </template>

      <template #right>
        <div
          class="container-right content__container-right content__container-right--sticky"
        >
          <div class="wrapper-sticky container-right__wrapper-sticky">
            <ApplicationNannyDetailsSectionPhoto
              :photo-main="application.details.photoMain"
              :photo-main-color-num="application.details.photoMainColorNum"
              :contractor-age="application.user_profile_id_populated.age"
              :photo-gallery="application.details.photoGallery"
              :is-photo-gallery-hidden="isPhotoGalleryHidden"
              :is-archived="isArchived"
            />
            <ButtonCta
              v-if="isClient && !isArchived"
              @click="openPopupCall"
              :label="'Zadzwoń'"
            />
          </div>
        </div>
      </template>
    </block-content-21>

    <ApplicationNannyDetailsSectionSchedule
      :schedule-date-start-string="application.schedule.dateStart.string"
      :schedule-date-start-days-before-start="
        application.schedule.dateStart.daysLeftBeforeStart
      "
      :schedule-data="application.schedule.value"
      :text-quote="textQuote"
      :fee-hour="application.details.rate.hour"
      :fee-month="application.details.rate.month"
      :schedule-comment="application.schedule.comment"
    />

    <ApplicationNannyDetailsSectionCareHome
      :home-care="application.responsibilities.careHome"
    />

    <div class="section-work-exp-edu content__section-work-exp-edu">
      <ApplicationNannyDetailsSectionExperienceWorking
        v-if="
          application.experience.working.isPresent &&
          application.experience.working.years > 0
        "
        :is-accent="true"
        :experience-years="application.experience.working.years"
        :experience-kids-ages="application.experience.working.kidsAges"
        :experience-comment="application.experience.working.comment"
      />
      <ApplicationNannyDetailsSectionEducation
        :is-accent="
          !application.experience.working.isPresent ||
          application.experience.working.years === 0
        "
        :education-degree="application.education.degree.label"
        :education-types="application.education.types"
        :education-courses="application.education.courses"
        :education-comment="application.education.comment"
      />
    </div>

    <block-content-21
      v-if="application.recommendation_ids_populated.length > 0"
    >
      <template #left>
        <ApplicationNannyDetailsSectionReviews
          :reviews="application.recommendation_ids_populated"
        />
      </template>

      <template #right>
        <div
          v-if="isClient"
          class="wrapper-review-sticky container-right__wrapper-review-sticky"
        >
          <span>Zadzwon do niani</span>
          <ButtonCta @click="openPopupCall" :label="'Zadzwoń'" />
        </div>
      </template>
    </block-content-21>

    <ApplicationNannyDetailsPopupCall
      v-if="canShowPopupCall"
      ref="popupCallRef"
      :nanny-phone="application.user_profile_id_populated.phone"
      :nanny-name="application.user_profile_id_populated.nameFirst"
    />

    <PopupPushToRegistration
      v-if="isAuth === false"
      ref="popupPushToRegistrationRef"
      :description="popupPushToRegDescription"
    />

    <BaseButtonIconWishlist
      v-if="isClient && !isArchived"
      @click="handleWishlistToggler"
      :is-wishlisted="isWishlisted"
      class="wishlist-button"
    />
  </div>
</template>

<script>
import intersectionDirective from "@/directives/intersection";

import useApplicationNannyDetails from "@/composables/useApplicationNannyDetails";
import { useRoute } from "vue-router";

import ApplicationNannyDetailsSectionTitle from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionTitle.vue";
import BlockTextExpandableMain from "@/components/BlockTextExpandableMain";
import ApplicationNannyDetailsSectionCareChild from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionCareChild.vue";
import ApplicationNannyDetailsSectionSchedule from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionSchedule.vue";
import ApplicationNannyDetailsSectionCareHome from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionCareHome";
import ApplicationNannyDetailsSectionExperienceWorking from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionExperienceWorking";
import ApplicationNannyDetailsSectionEducation from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionEducation";
import ApplicationNannyDetailsSectionReviews from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionReviews.vue";
import BaseGroupItemIconLabel from "@/components/UI/Base/BaseGroupIconLabel.vue";
import ApplicationNannyDetailsSectionPhoto from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionPhoto.vue";
import ButtonCta from "@/components/UI/Base/ButtonCta.vue";
import ApplicationNannyDetailsPopupCall from "@/components/Application/Nanny/Details/ApplicationNannyDetailsPopupCall.vue";
import PopupPushToRegistration from "@/components/PopupPushToRegistration.vue";
import useAuth from "@/composables/useAuth";
import BaseButtonIconWishlist from "@/components/UI/Base/BaseButtonIconWishlist.vue";

export default {
  directives: {
    intersection: intersectionDirective,
  },

  components: {
    ApplicationNannyDetailsSectionTitle,
    BlockTextExpandableMain,
    ApplicationNannyDetailsSectionCareChild,
    ApplicationNannyDetailsSectionSchedule,
    ApplicationNannyDetailsSectionCareHome,
    ApplicationNannyDetailsSectionExperienceWorking,
    ApplicationNannyDetailsSectionEducation,
    ApplicationNannyDetailsSectionReviews,
    BaseGroupItemIconLabel,
    ApplicationNannyDetailsSectionPhoto,
    ButtonCta,
    ApplicationNannyDetailsPopupCall,
    PopupPushToRegistration,
    BaseButtonIconWishlist,
  },

  setup() {
    const route = useRoute();
    const { isAuth } = useAuth();
    const {
      application,
      handleTitleIntersection,
      isPhotoGalleryHidden,
      textQuote,
      handleWishlistToggler,
      isWishlisted,

      isClient,
      isArchived,
      popupCallRef,
      openPopupCall,
      canShowPopupCall,

      popupPushToRegistrationRef,
      popupPushToRegDescription,
    } = useApplicationNannyDetails(route.params.id);

    return {
      isAuth,
      application,
      handleTitleIntersection,
      handleWishlistToggler,
      isPhotoGalleryHidden,
      textQuote,
      isClient,
      isArchived,
      isWishlisted,

      popupCallRef,
      openPopupCall,
      canShowPopupCall,

      popupPushToRegistrationRef,
      popupPushToRegDescription,
    };
  },
};
</script>

<style scoped>
.content {
  padding: 100px 0;
  display: flex;
  flex-flow: column;
  align-items: center;

  row-gap: 100px;
}
.content__container-left {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 100px;
}
.content__container-right {
  height: 100%;
}
.container-right__wrapper-sticky {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 40px;

  position: sticky;
  top: 100px;
}
.content__section-work-exp-edu {
  width: 100%;

  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  column-gap: 40px;
}
.container-right__wrapper-review-sticky {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 20px;

  position: sticky;
  top: calc((100vh - 107px - 47px) / 2);
}
.wishlist-button {
  position: fixed;
  top: calc(100vh / 2 - 36px);
  right: calc((100vw - var(--layout-default--width)) / 4 - 36px / 2);
}
</style>
