<template>
  <BasePopup ref="popupRef">
    <div class="popup-content">
      <BaseH1 :text="'Zadzwoń nianię'" />
      <TextDescription :text="`( ${nannyName} )`" />
      <ButtonLink
        @click="handlePhoneClick"
        :label="nannyPhone"
        class="popup-content__phone"
      />
    </div>
  </BasePopup>
</template>

<script>
import { ref } from "vue";

import BasePopup from "@/components/BasePopup.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextDescription from "@/components/UI/TextDescription.vue";
import ButtonLink from "@/components/UI/Base/ButtonLink.vue";

export default {
  components: {
    BasePopup,
    BaseH1,
    TextDescription,
    ButtonLink,
  },

  props: {
    nannyName: String,
    nannyPhone: String,
  },

  expose: ["open", "close"],

  setup(props) {
    const popupRef = ref(null);

    const open = () => {
      if (popupRef.value === null) {
        return;
      }
      popupRef.value.open();
    };

    const close = () => {
      if (popupRef.value === null) {
        return;
      }
      popupRef.value.close();
    };

    const handlePhoneClick = () => {
      // TODO: rewrite with a regex (to replace '-' and any other odd symbols)
      window.open(`tel:${props.nannyPhone.replaceAll(" ", "")}`);
    };

    return {
      popupRef,
      handlePhoneClick,
      open,
      close,
    };
  },
};
</script>

<style scoped>
.popup-content {
  margin: auto;
  padding: 0px 60px;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 40px;
}
.popup-content .popup-content__phone {
  font-size: 26px;
}

@media (max-width: 1200px) {
  .popup-content {
    width: var(--layout-default--width);
    margin: auto;
    padding: 0px;
    display: flex;
    flex-flow: column;
    row-gap: 50px;
  }
}
</style>
