<template>
  <div
    class="section-job-exp"
    :class="isAccent ? 'section-job-exp--accent' : ''"
  >
    <div class="title-wrapper section-job-exp__title-wrapper">
      <BaseH2 v-html="'Doświadczenie w pracy'" />
      <BaseGroupChipItem
        :label="experienceYears + ' lata'"
        :style="'font-weight: 500; background-color: rgba(191, 179, 249, 0.5);'"
      />
    </div>

    <BaseText
      >z dziećmi w wieku <b>{{ experienceKidsAges.join(", ") }}</b></BaseText
    >

    <BlockTextExpandableComment
      v-if="experienceComment"
      :text="experienceComment"
      :max-length="200"
    />
  </div>
</template>

<script>
import BlockTextExpandableComment from "@/components/BlockTextExpandableComment.vue";
import BaseGroupChipItem from "@/components/UI/Base/BaseGroupChipItem.vue";
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseText from "@/components/UI/Base/BaseText.vue";

export default {
  components: {
    BlockTextExpandableComment,
    BaseGroupChipItem,
    BaseH2,
    BaseText,
  },

  props: {
    isAccent: {
      type: Boolean,
      default: false,
    },
    experienceYears: [String, Number],
    experienceKidsAges: Array,
    experienceComment: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped>
.section-job-exp {
  padding: 50px 60px 60px 60px;
  width: var(--layout-view-block-11--column-width);

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 40px;
}
.section-job-exp--accent {
  background: #fff;
  border-radius: 10px;
}
.section-job-exp__title-wrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 10px;
}

@media (max-width: 1200px) {
  .section-job-exp {
    padding: 0;

    display: flex;
    flex-flow: column;
    align-items: flex-start;
    row-gap: 20px;
  }
  .section-job-exp__title-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    column-gap: 10px;
    row-gap: 5px;
  }
}
</style>
