<template>
  <div class="section-photo">
    <div
      v-if="!isArchived"
      class="activeness section-photo__activeness"
      :class="`activeness--${activeness.slug}`"
    >
      <span class="icon activeness__icon"></span>
      <label class="label activeness__label">{{ activeness.label }}</label>
    </div>
    <div class="section-photo__gallery-wrapper">
      <BaseTag
        v-if="isArchived"
        :text="'Ankieta archiwalna'"
        class="section-photo__archived-tag"
      />
      <PhotoGalleryMobile
        :photoMainImg="photoMain"
        :photoGalleryImgs="isArchived ? [] : photoGallery"
        :defaultColorNum="photoMainColorNum"
        :yearsLabelString="yearsLabelString"
        :class="['section-photo__gallery', { 'is-archived': isArchived }]"
      />
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

import PhotoGalleryMobile from "@/components/PhotoGalleryMobile.vue";
import BaseTag from "@/components/UI/Base/BaseTag.vue";

export default {
  components: {
    PhotoGalleryMobile,
    BaseTag,
  },

  props: {
    activeness: Object,
    photoMain: Object,
    photoMainColorNum: Number,
    photoGallery: Array,
    contractorAge: Object,
    isArchived: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const yearsLabelString = computed(() => {
      return `${props.contractorAge.years} lat`;
    });
    return { yearsLabelString };
  },
};
</script>

<style scoped>
.section-photo {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
}
.section-photo__gallery-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}
.section-photo__gallery.is-archived {
  pointer-events: none;
  opacity: 0.5;
}
.section-photo__archived-tag {
  position: absolute;
  top: calc(var(--tag-height) / -2);
}
.section-photo__activeness {
  margin: 5px auto;

  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 9px;

  font: var(--system-font);
}
.activeness__icon {
  width: 10px;
  height: 10px;
  margin-top: 3px;

  border-radius: 5px;
}
.activeness--actual .activeness__label {
  color: rgba(22, 147, 155, 0.8);
}
.activeness--actual .activeness__icon {
  background-color: #72f15d;
}
.activeness--outdated .activeness__label {
  color: #fe881c;
}
.activeness--outdated .activeness__icon {
  background-color: #fe881c;
}
</style>
