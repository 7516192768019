<template>
  <div class="section-care-home">
    <BaseH2 v-html="'Pomoc domowa'" />
    <BaseGroupIconLabelRound :items="readyHomeCareItems" />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

import useApplicationNannyDictionary from "@/composables/useApplicationNannyDictionary";

import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BaseGroupIconLabelRound from "@/components/UI/Base/BaseGroupIconLabelRound.vue";

export default {
  components: { BaseH2, BaseGroupIconLabelRound },

  props: {
    homeCare: Array,
  },

  setup(props) {
    const { DICTIONARY } = useApplicationNannyDictionary();
    const HOME_CARE_DICT = DICTIONARY.responsibilities.homeCare;

    const readyHomeCareItems = computed(() => {
      const homeCareItems = props.homeCare;
      const result = [];

      if (homeCareItems) {
        let allHomeCareSlugs = Object.keys(HOME_CARE_DICT);
        allHomeCareSlugs.forEach((slug) => {
          const readyItem = {
            ...HOME_CARE_DICT[slug],
            isPresent: homeCareItems.some((item) => item.slug === slug),
          };
          result.push(readyItem);
        });
      }

      return result;
    });

    return { readyHomeCareItems };
  },
};
</script>

<style scoped>
.section-care-home {
  width: 100%;

  display: flex;
  flex-flow: column;
  row-gap: 25px;
}

@media (max-width: 1200px) {
  .section-care-home {
    width: 100%;

    display: flex;
    flex-flow: column;
    row-gap: 20px;
  }
}
</style>
