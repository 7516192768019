<template>
  <div class="list-reviews">
    <ApplicationNannyDetailsSectionReviewsListItem
      v-for="review in reviews"
      :key="review.id"
      :id="review.id"
      :proof-name="review.proofName"
      :review-text="review.text"
      :source="review.source"
      :is-verified="review.isVerified"
      :rating="review.rating"
    />
  </div>
</template>

<script>
import ApplicationNannyDetailsSectionReviewsListItem from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionReviewsListItem.vue";

export default {
  components: { ApplicationNannyDetailsSectionReviewsListItem },

  props: {
    reviews: Array,
  },
};
</script>

<style scoped>
.list-reviews {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 60px;
}

@media (max-width: 1200px) {
  .list-reviews {
    width: 100%;

    display: flex;
    flex-flow: column;
    align-items: flex-start;
    row-gap: 30px;
  }
}
</style>
