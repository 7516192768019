let observer;
const intersectionDirective = {
  mounted(el, binding) {
    const options = binding.value || {
      threshold: 0.5,
    };
    console.log({ options });
    console.dir(el);
    let isIntersecting;
    const callback = (entries) => {
      if (isIntersecting !== entries[0].isIntersecting) {
        const intersectionEvent = new CustomEvent("intersection", {
          detail: { isOnScreen: entries[0].isIntersecting },
        });

        el.dispatchEvent(intersectionEvent);
      } else {
        isIntersecting = entries[0].isIntersecting;
      }
    };
    observer = new IntersectionObserver(callback, options);
    observer.observe(el);
  },
  beforeUnmount(el) {
    observer.unobserve(el);
  },
};

export default intersectionDirective;
