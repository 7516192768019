<template>
  <div class="section-care-child">
    <BaseH2 v-html="'Pomoc z dzieckiem'" />
    <BlockGroupChips
      :block-title="'Opieka'"
      :items="childCare"
      :items-crossed="crossedChildCare"
    />
    <BlockGroupChips
      :block-title="'Mogę zajmować się...'"
      :items="readyActivities"
    />
  </div>
</template>

<script>
import { computed } from "@vue/reactivity";

import useApplicationNannyDictionary from "@/composables/useApplicationNannyDictionary";

import BlockGroupChips from "@/components/BlockGroupChips.vue";
import BaseH2 from "@/components/UI/Base/BaseH2.vue";

export default {
  components: { BlockGroupChips, BaseH2 },

  props: {
    childCare: Array,
    activities: Array,
  },

  setup(props) {
    const { DICTIONARY } = useApplicationNannyDictionary();

    const readyActivities = computed(() => {
      let activities = [];
      if (props.activities.length) {
        props.activities.forEach((activity) => {
          activities.push({
            slug: activity.slug,
            label: activity.labelShort,
          });
        });
      }

      return activities;
    });

    const crossedChildCare = computed(() => {
      const childCareItems = props.childCare;
      const notIncludedEntries = [];

      if (childCareItems) {
        let allChildCareSlugs = Object.keys(
          DICTIONARY.responsibilities.childCare
        );
        for (let slug of allChildCareSlugs) {
          if (!childCareItems.some((item) => item.slug === slug)) {
            notIncludedEntries.push(
              DICTIONARY.responsibilities.childCare[slug]
            );
          }
        }
      }

      return notIncludedEntries;
    });

    return { readyActivities, crossedChildCare };
  },
};
</script>

<style scoped>
.section-care-child {
  padding: 50px 70px 70px 70px;
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 50px;

  background: #f5faff;
  border-radius: 10px;
}
</style>
