<template>
  <div class="block">
    <div
      class="activeness block__activeness"
      :class="`activeness--${activeness.slug}`"
    >
      <span class="icon activeness__icon"></span>
      <label class="label activeness__label">{{ activeness.label }}</label>
    </div>
    <div class="title block__title">
      <BaseH1 :short="true">{{ title }}</BaseH1>
      <div class="geo title__geo">
        <SvgIconsLocation24 class="icon geo__icon" />
        <label class="label geo__label">{{ geoLabel }} od Ciebie</label>
      </div>
    </div>
    <div class="languages block__languages">
      <span>Mowię w językach</span>
      <img
        v-for="language in languages"
        :key="language.label"
        :src="language.iconSrc"
        class="icon languages__icon"
      />
    </div>
  </div>
</template>

<script>
import SvgIconsLocation24 from "@/components/Svg/Icons/SvgIconsLocation24.vue";
import BaseH1 from "@/components/UI/Base/BaseH1.vue";

export default {
  components: { SvgIconsLocation24, BaseH1 },

  props: {
    title: {
      type: String,
      required: true,
    },
    languages: Array,
    activeness: Object,
    geoLabel: String,
  },
};
</script>

<style scoped>
.block {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 40px;
}
.block__activeness {
  display: flex;
  flex-flow: row;
  align-items: center;
  column-gap: 9px;

  font: var(--system-font);
}
.activeness__icon {
  width: 10px;
  height: 10px;
  margin-top: 3px;
  border-radius: 5px;
}
.activeness--actual .activeness__label {
  color: rgba(22, 147, 155, 0.8);
}
.activeness--actual .activeness__icon {
  background-color: #72f15d;
}
.activeness--outdated .activeness__label {
  color: #fe881c;
}
.activeness--outdated .activeness__icon {
  background-color: #fe881c;
}

.block__title {
  display: flex;
  flex-flow: row;
  column-gap: 70px;
  align-items: baseline;
}
.title__geo {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
  column-gap: 5px;
}
.geo__label {
  font: var(--system-font);
}
.block__languages {
  display: flex;
  flex-flow: row;
  column-gap: 20px;
  align-items: center;
}
.languages__icon {
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 20px;
}
</style>
