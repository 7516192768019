<template>
  <div class="section-reviews">
    <BaseH2 v-html="'Opinia'" />
    <ApplicationNannyDetailsSectionReviewsList :reviews="reviews" />
  </div>
</template>

<script>
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import ApplicationNannyDetailsSectionReviewsList from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionReviewsList.vue";

export default {
  components: { BaseH2, ApplicationNannyDetailsSectionReviewsList },

  props: {
    reviews: Array,
  },
};
</script>

<style scoped>
.section-reviews {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: flex-start;
  row-gap: 25px;
}
@media (max-width: 1200px) {
  .section-reviews {
    row-gap: 20px;
  }
}
</style>
