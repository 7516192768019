<template>
  <div class="section-about-me">
    <BaseH2 v-html="'O mnie'" />
    <BlockTextExpandableMain :text="textAboutMe" />
  </div>
</template>

<script>
import BaseH2 from "@/components/UI/Base/BaseH2.vue";
import BlockTextExpandableMain from "@/components/BlockTextExpandableMain.vue";

export default {
  components: { BaseH2, BlockTextExpandableMain },

  props: {
    textAboutMe: String,
  },
};
</script>

<style scoped>
.section-about-me {
  width: 100%;

  display: flex;
  flex-flow: column;
  row-gap: 20px;
}
</style>
