<template>
  <div :class="['photo-gallery-container', `${mode}`]">
    <div class="photo-gallery-container__frame-holder">
      <div
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd"
        @touchcancel="handleTouchEnd"
        class="frame"
      >
        <div v-if="currentFrameString" class="frame-num frame__frame-num">
          {{ currentFrameString }}
        </div>

        <div
          ref="galleryRef"
          @click="openFullScreenMode"
          @scroll="handleGalleryScroll"
          class="photo-gallery scrollSnap"
        >
          <div class="photo-main photo-gallery__photo-main">
            <img
              v-if="photoMainImg"
              :src="photoMainImg.src"
              :srcset="photoMainImg.srcsetJpg"
              class="img img-0 photo-main__img"
            />
            <div
              v-else
              :style="`background-color: ${profileColor}`"
              class="img img-0 photo-main__img"
            ></div>
            <span
              v-if="yearsLabelString"
              class="label-age photo-main__label-age"
              >{{ yearsLabelString }}</span
            >
          </div>
          <img
            v-for="(image, n) in photoGalleryImgs"
            :key="image.src"
            :src="image.src"
            :srcset="image.srcsetJpg"
            :class="['img', `img-${n + 1}`, 'photo-gallery__img']"
          />
          <div id="galleryWider"></div>
        </div>
      </div>
    </div>

    <div
      v-if="frameAmount > 1"
      class="frame-num-points photo-gallery-container__frame-num-points"
    >
      <div
        v-for="n in frameAmount"
        :key="n"
        :active="n === currentFrameIndex + 1"
        class="point frame-num-points__point"
      ></div>
    </div>

    <NavMobileClose
      v-if="mode === 'full-screen'"
      @close="closeFullScreenMode"
      class="gallery-mobile-nav-menu"
    />
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";

import useProfileColor from "@/composables/useProfileColor";
import useViewportUnits from "@/composables/useViewportUnits";
import usePhotoGallery from "@/composables/usePhotoGallery";

import NavMobileClose from "@/components/NavMobileClose.vue";

export default {
  components: { NavMobileClose },

  props: {
    photoMainImg: Object,
    photoGalleryImgs: Array,
    yearsLabelString: {
      type: String,
      required: true,
    },
    defaultColorNum: Number,
  },

  setup(props) {
    // TODO: take column-gap from computedStyle
    const FRAME_GAP = 20;
    const galleryRef = ref(null);
    const frameAmount = computed(() => {
      return props.photoGalleryImgs.length + 1;
    });

    const framePreviewWidth = computed(() => {
      if (galleryRef.value === null) {
        return 0;
      }
      return galleryRef.value.parentElement.parentElement.offsetWidth;
    });

    const currentImageElement = computed(() => {
      let index;
      if (!_currentFrameIndex?.value) {
        index = 0;
      } else {
        index = _currentFrameIndex.value;
      }
      return document.querySelector(`.photo-gallery .img-${index}`);
    });

    const {
      _currentFrameIndex,
      _mode,

      openFullScreenMode,
      closeFullScreenMode,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
      onHorizontalScroll,
    } = usePhotoGallery(galleryRef, {
      frameGap: FRAME_GAP,
      frameAmountRef: frameAmount,
      currentImageElementRef: currentImageElement,
      framePreviewWidthRef: framePreviewWidth,
    });

    const currentFrameString = computed(() => {
      if (frameAmount.value === 1) {
        return "";
      }
      return `${_currentFrameIndex.value + 1} / ${frameAmount.value}`;
    });

    const handleTouchStart = (event) => {
      onTouchStart(event);
    };

    const handleTouchMove = (event) => {
      onTouchMove(event);
    };

    const handleTouchEnd = (event) => {
      onTouchEnd(event);
    };

    const handleGalleryScroll = (event) => {
      onHorizontalScroll(event);
    };

    watch(_mode, (newMode) => {
      if (newMode === "full-screen") {
        document.body.classList.add("noscroll");
      } else if (newMode === "preview") {
        document.body.classList.remove("noscroll");
      }
    });

    return {
      frameAmount,
      currentFrameIndex: _currentFrameIndex,
      currentFrameString,
      mode: _mode,

      openFullScreenMode,
      closeFullScreenMode,
      handleTouchStart,
      handleTouchMove,
      handleTouchEnd,
      handleGalleryScroll,

      galleryRef,

      ...useProfileColor(props.defaultColorNum),
      ...useViewportUnits(),
    };
  },
};
</script>

<style scoped>
.photo-gallery-container {
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 8px;

  --vw: v-bind("vwString");
  --vh: v-bind("vhString");

  /* FIX FOR IOS VARS in position absolute */
  --nav-mobile-height: 50px;

  --full-screen-frame-height: calc(100 * var(--vh) - var(--nav-mobile-height));
  --photo-gallery-height: calc(
    var(--full-screen-frame-height) - var(--nav-mobile-height)
  );
}
.gallery-mobile-nav-menu {
  z-index: 9999;
}
.photo-gallery-container__frame-holder {
  width: var(--layout-default--width);
  height: var(--layout-default--width); /* mobile height */
}
.frame {
  position: relative;

  will-change: height, top, left;
  isolation: isolate;
}
.preview .frame {
  width: var(--layout-default--width);
  height: var(--layout-default--width); /* mobile height */
  border-radius: var(--button-border-radius);
}
.full-screen .frame {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;

  width: calc(100 * var(--vw));
  height: var(--full-screen-frame-height);

  display: flex;
  align-items: flex-end;
  background-color: #ffffff;
  border-radius: 0px;
}
.frame__frame-num {
  width: 44px;
  height: 23px;

  position: absolute;
  top: 12px;
  right: 14px;
  z-index: 9;

  color: #ffffff;
  font-size: 12px;
  text-align: center;
  line-height: 23px;
  border-radius: 20px;
  background: rgba(22, 32, 94, 0.2);
  backdrop-filter: blur(15px);
}
.full-screen .frame__frame-num {
  position: fixed;
  color: var(--default-font-color);
  background: rgba(22, 32, 94, 0);
}
.photo-gallery {
  width: 100%;

  display: flex;
  --photo-gallery--column-gap: 20px;
  column-gap: var(--photo-gallery--column-gap);
  will-change: transform, height;

  overflow-x: scroll;
  overflow-y: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
#galleryWider {
  margin-left: calc(-1 * var(--photo-gallery--column-gap));
}
.photo-gallery.scrollSnap {
  scroll-snap-type: x mandatory;
}
.photo-gallery.noscroll {
  scroll-snap-type: none;
  touch-action: none;
}
.photo-gallery::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
  display: none;
}
.preview .photo-gallery {
  height: var(--layout-default--width);
  border-radius: var(--button-border-radius);
}
.full-screen .photo-gallery {
  height: var(--photo-gallery-height);
  border-radius: 0px;
}
.photo-gallery .img {
  will-change: width, height;

  scroll-snap-stop: always;
  scroll-snap-align: center;
}
.preview .img {
  touch-action: pan-x pan-y;
  width: var(--layout-default--width);
  height: var(--layout-default--width);
  min-width: var(--layout-default--width);
  min-height: var(--layout-default--width);
  object-fit: cover;
  border-radius: var(--button-border-radius);
}
.full-screen .img {
  touch-action: pan-x pinch-zoom;
  width: calc(100 * var(--vw));
  height: var(--photo-gallery-height);
  min-width: calc(100 * var(--vw));
  min-height: var(--photo-gallery-height);
  object-fit: contain;
  align-self: center;
  border-radius: 0px;
}
.photo-gallery__photo-main {
  position: relative;
}
.full-screen .photo-gallery__photo-main {
  height: max-content;
  align-self: center;
}
.photo-main__label-age {
  padding: 4px 12px;
  position: absolute;
  left: 0;
  bottom: 25px;

  background-color: #000;
  border-radius: 0 20px 20px 0;

  color: #fff;
  text-align: center;
}
.full-screen .photo-main__label-age {
  opacity: 0;
}
.photo-gallery-container__frame-num-points {
  display: flex;
  column-gap: 4px;
}
.frame-num-points__point {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #16205e;
  opacity: 0.4;
}
.frame-num-points__point[active="true"] {
  opacity: 1;
}
</style>
