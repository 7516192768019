<template>
  <div class="nav-mobile-close-wrapper">
    <nav class="nav-mobile-close">
      <BaseButtonIconArrowBack
        @click="$emit('close')"
        :size="'small'"
        class="button-icon nav-mobile-close__button-icon"
      />
    </nav>
  </div>
</template>

<script>
import BaseButtonIconArrowBack from "@/components/UI/Base/BaseButtonIconArrowBack.vue";

export default {
  components: { BaseButtonIconArrowBack },

  emits: ["close"],
};
</script>

<style scoped>
.nav-mobile-close-wrapper {
  width: 100vw;

  position: fixed;
  bottom: 0;
  left: 0;

  background-color: var(--main-bg-color);
  border-top: 1px solid rgba(22, 32, 94, 0.06);
}
.nav-mobile-close {
  width: var(--layout-default--width);
  height: 50px;

  margin: 0 auto;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
}
.nav-mobile-close__button-icon {
  width: calc((100% - 3 * 10px) / 4);
  opacity: 0.4;
}
.nav-mobile-close__button-icon:active {
  opacity: 1;
}
</style>
