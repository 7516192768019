<template>
  <component :is="navComponent" />
</template>

<script>
import { computed, defineAsyncComponent } from "vue";
import { useUserStore } from "@/stores/user";

export default {
  setup() {
    const user = useUserStore();

    const navComponent = computed(() => {
      if (user.userType === "contractor") {
        return defineAsyncComponent(() =>
          import(
            "@/components/Application/Nanny/Details/NavMobile/ApplicationNannyDetailsNavMobileContractor.vue"
          )
        );
      }

      // Return Client navigation by default (for anonymus and client)
      return defineAsyncComponent(() =>
        import(
          "@/components/Application/Nanny/Details/NavMobile/ApplicationNannyDetailsNavMobileClient.vue"
        )
      );
    });

    return { navComponent };
  },
};
</script>
