<template>
  <div class="content" v-if="application">
    <ApplicationNannyDetailsSectionPhotoMobile
      :activeness="application.user_profile_id_populated.activeness"
      :photo-main="application.details.photoMain"
      :photo-main-color-num="application.details.photoMainColorNum"
      :contractor-age="application.user_profile_id_populated.age"
      :photo-gallery="application.details.photoGallery"
      :is-archived="isArchived"
    />
    <ApplicationNannyDetailsSectionTitleMobile
      :title="application.user_profile_id_populated.nameFull"
      :geo-label="application.details.location.label"
      class="section-title content__section-title"
    />
    <BlockTextQoute :text="textQuote" />
    <BlockFee
      :fee-hour="application.details.rate.hour"
      :fee-month="application.details.rate.month"
    />
    <div
      class="section-important-statement content__section-important-statement"
    >
      <p>
        <b>{{ application.experience.working.years }}</b> l. doświadczenia
      </p>
    </div>
    <ApplicationNannyDetailsSectionAdvantagesMobile
      :advantages="application.advantages"
      :languages="application.details.languages"
    />
    <ApplicationNannyDetailsSectionCareChildMobile
      :child-care="application.responsibilities.careChild"
    />
    <ApplicationNannyDetailsSectionCareActivitiesMobile
      :activities="application.responsibilities.activities"
    />
    <ApplicationNannyDetailsSectionScheduleMobile
      :schedule-date-start-string="application.schedule.dateStart.string"
      :schedule-date-start-days-before-start="
        application.schedule.dateStart.daysLeftBeforeStart
      "
      :schedule-data="application.schedule.value"
      :schedule-comment="application.schedule.comment"
    />
    <ApplicationNannyDetailsSectionCareHome
      :home-care="application.responsibilities.careHome"
    />
    <ApplicationNannyDetailsSectionExperienceWorking
      v-if="
        application.experience.working.isPresent &&
        application.experience.working.years > 0
      "
      :experience-years="application.experience.working.years"
      :experience-kids-ages="application.experience.working.kidsAges"
      :experience-comment="application.experience.working.comment"
    />
    <ApplicationNannyDetailsSectionEducation
      :education-degree="application.education.degree.label"
      :education-types="application.education.types"
      :education-courses="application.education.courses"
      :education-comment="application.education.comment"
    />
    <ApplicationNannyDetailsSectionAboutMeMobile
      :text-about-me="application.details.description"
    />
    <ApplicationNannyDetailsSectionReviews
      v-if="application.recommendation_ids_populated.length > 0"
      :reviews="application.recommendation_ids_populated"
    />

    <!-- 
    <ButtonCta :label="'Zadzwoń'" />
     -->

    <ApplicationNannyDetailsNavMobile />
  </div>
</template>

<script>
// import intersectionDirective from "@/directives/intersection";

import useApplicationNannyDetails from "@/composables/useApplicationNannyDetails";
import { useRoute } from "vue-router";
import ApplicationNannyDetailsSectionTitleMobile from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionTitleMobile.vue";
import ApplicationNannyDetailsSectionPhotoMobile from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionPhotoMobile.vue";
import BlockTextQoute from "@/components/BlockTextQoute.vue";
import BlockFee from "@/components/BlockFee.vue";
import ApplicationNannyDetailsSectionAdvantagesMobile from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionAdvantagesMobile.vue";
import ApplicationNannyDetailsSectionCareChildMobile from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionCareChildMobile.vue";
import ApplicationNannyDetailsSectionCareActivitiesMobile from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionCareActivitiesMobile.vue";
import ApplicationNannyDetailsSectionScheduleMobile from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionScheduleMobile.vue";
import ApplicationNannyDetailsSectionCareHome from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionCareHome.vue";
import ApplicationNannyDetailsSectionExperienceWorking from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionExperienceWorking.vue";
import ApplicationNannyDetailsSectionEducation from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionEducation.vue";
import ApplicationNannyDetailsSectionAboutMeMobile from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionAboutMeMobile.vue";
import ApplicationNannyDetailsSectionReviews from "@/components/Application/Nanny/Details/ApplicationNannyDetailsSectionReviews.vue";
import ApplicationNannyDetailsNavMobile from "@/components/Application/Nanny/Details//NavMobile/ApplicationNannyDetailsNavMobile.vue";

// import ButtonCta from "@/components/UI/Base/ButtonCta.vue";

export default {
  directives: {
    // intersection: intersectionDirective,
  },

  components: {
    ApplicationNannyDetailsSectionTitleMobile,
    ApplicationNannyDetailsSectionPhotoMobile,
    BlockTextQoute,
    BlockFee,
    ApplicationNannyDetailsSectionAdvantagesMobile,
    ApplicationNannyDetailsSectionCareChildMobile,
    ApplicationNannyDetailsSectionCareActivitiesMobile,
    ApplicationNannyDetailsSectionScheduleMobile,
    ApplicationNannyDetailsSectionCareHome,
    ApplicationNannyDetailsSectionExperienceWorking,
    ApplicationNannyDetailsSectionEducation,
    ApplicationNannyDetailsSectionAboutMeMobile,
    ApplicationNannyDetailsSectionReviews,
    ApplicationNannyDetailsNavMobile,
  },

  setup() {
    const route = useRoute();
    const { application, textQuote, isArchived } = useApplicationNannyDetails(
      route.params.id
    );

    return { application, textQuote, isArchived };
  },
};
</script>

<style scoped>
.content {
  margin-bottom: 30px;
  display: flex;
  flex-flow: column;
  align-items: center;

  row-gap: 60px;
}
.content__section-title {
  margin: -20px 0 -10px;
}

.content__section-important-statement {
  font-size: 22px;
}
</style>
